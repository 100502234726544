import "index.css"
import "syntax-highlighting.css"


const shakeObserver = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add("tada");
    }
  });
});

shakeObserver.observe(document.querySelector("p.free-chapters"));

class DismissableBanner extends HTMLElement {
  connectedCallback() {
    this.button = this.querySelector("button")

    this.#setupButtonHandler()
    this.#hideIfDismissed()
  }

  #setupButtonHandler() {
    this.button.addEventListener('click', (event) => {
      this.remove()
      localStorage.setItem("banner_dismissed", true)
    })
  }

  #hideIfDismissed() {
    const dismissed = localStorage.getItem("banner_dismissed")

    if (dismissed) {
      this.style.display = "none"
    }
  }
}

customElements.define("dismissable-banner", DismissableBanner);